<template>
  <div class="rankingList-listContent">
    <div class="grid-content bg-purple-dark" style="margin: 0 auto">
      <!-- @tab-click="handleClick" -->
      <el-tabs
        v-model="activeName"
        style="height: 433px; overflow: hidden; overflow-y: scroll"
        @tab-click="setData"
      >
        <el-tab-pane label="城市" name="first">
          <span slot="label">
            <div class="parkLable" style="position: relative">
              城市
              <span
                @click.stop="
                  activeStatus === 1 ? (activeStatus = 2) : (activeStatus = 1);
                  activeStatus2 = 2;
                  activeStatus3 = 2;
                  getRankList(3, activeStatus);
                "
                :class="{ isActive: activeStatus === 1 }"
                class="parkLable_top"
              />
              <span
                @click.stop="
                  activeStatus === 0 ? (activeStatus = 2) : (activeStatus = 0);
                  activeStatus2 = 2;
                  activeStatus3 = 2;
                  getRankList(3, activeStatus);
                "
                :class="{ isActive: activeStatus === 0 }"
                class="parkLable_bottom"
              />
            </div>
          </span>
          <el-row v-for="(item, index) in parking.slice(0, 10)" :key="index" class="row" >
            <el-col class="col" >
              <span class="indexNumber">{{ index + 1 }}</span>
              <span class="item">{{ item.areaName }}</span>
              <span class="itemMoney">{{
                `${item.bertCount} | ${Number(item.parkResourceRate*100).toFixed(2) + '%'}`
              }}</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$t('searchModule.region')" name="second">
          <span slot="label">
            <div class="parkLable" style="position: relative">
              区域
              <span
                @click.stop="
                  activeStatus2 === 1 ? (activeStatus2 = 2) : (activeStatus2 = 1);
                  activeStatus = 2;
                  activeStatus3 = 2;
                  getRankList(4, activeStatus2);
                "
                :class="{ isActive: activeStatus2 === 1 }"
                class="parkLable_top"
                style="right: -6px"
              />
              <span
                @click.stop="
                  activeStatus2 === 0 ? (activeStatus2 = 2) : (activeStatus2 = 0);
                  activeStatus = 2;
                  activeStatus3 = 2;
                  getRankList(4, activeStatus2);
                "
                :class="{ isActive: activeStatus2 === 0 }"
                class="parkLable_bottom"
                style="right: -6px"
              />
            </div>
          </span>
          <el-row v-for="(item, index) in parking.slice(0, 10)" :key="index" class="row">
            <el-col class="col">
              <span class="indexNumber">{{ index + 1 }}</span>
              <span class="item">{{ item.areaName }}</span>
              <!-- <span class="itemMoney1">{{ item.totalBerthCount }}</span> -->
              <span class="itemMoney">{{
                `${item.bertCount} | ${Number(item.parkResourceRate*100).toFixed(2) + '%'}`
              }}</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$t('searchModule.Road_or_Parking_Lot')" name="third" style="margin-right: 50px;">
          <span slot="label">
            <div class="parkLable" style="padding-right: 30px;">
              道路/车场
              <span
                @click.stop="
                  activeStatus3 === 1 ? (activeStatus3 = 2) : (activeStatus3 = 1);
                  activeStatus = 2;
                  activeStatus2 = 2;
                  getRankList(-1, activeStatus3);
                "
                :class="{ isActive: activeStatus3 === 1 }"
                class="parkLable_top"
                style="right: 14px"
              />
              <span
                @click.stop="
                  activeStatus3 === 0 ? (activeStatus3 = 2) : (activeStatus3 = 0);
                  activeStatus = 2;
                  activeStatus2 = 2;
                  getRankList(-1, activeStatus3);
                "
                :class="{ isActive: activeStatus3 === 0 }"
                class="parkLable_bottom"
                style="right: 14px"
              />
            </div>
          </span>
          <el-row v-for="(item, index) in parking.slice(0, 10)" :key="index" class="row">
            <el-col class="col">
              <span class="indexNumber">{{ index + 1 }}</span>
              <span class="item">{{ item.parkName }}</span>
              <span class="itemMoney">{{
                `${item.bertCount} | ${Number(item.parkResourceRate*100).toFixed(2) + '%'}`
              }}</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane disabled label="泊位数(个)" name="a"> </el-tab-pane>
        <el-tab-pane disabled label="利用率" name="b"> </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <div class="berthNumer">泊位数(个)</div>
    <div class="utilization">利用率</div> -->
  </div>
</template>
<script>
import ranking1 from "@/assets/img/ranking1.png";
import ranking2 from "@/assets/img/ranking2.png";
import ranking3 from "@/assets/img/ranking3.png";
export default {
  props: {
    parking: {
      default: function () {
        // 街道
        return [];
      },
    },
    rankingNumber: {
      default: function () {
        return [];
      },
    },
    activeNameTwo: {
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      activeName: "first",
      ranking1: ranking1,
      ranking2: ranking2,
      ranking3: ranking3,
      activeStatus: 2,
      activeStatus2: 2,
      activeStatus3: 2,
    };
  },
  watch: {
    parking: {
      handler(curVal, oldVal) {},
      immediate: true,
    },
    rankingNumber: {
      handler(curVal, oldVal) {
        if (curVal === 2) {
          this.activeName = "first";
        }
        this.Number = curVal;
      },
      immediate: true,
    },
    activeNameTwo: {
      handler(curVal, oldVal) {
        if (curVal === "first") {
          this.activeName = "first";
        } else {
          this.activeName = "first";
        }
      },
      immediate: true,
    },
  },
  methods: {
    setData(tab, event) {
      if (this.Number === 2) {
        if (tab.name == "first") {
          this.$emit("getranking", 3, null);
        } else if (tab.name == "second") {
          this.$emit("getranking", 4, null);
        } else if (tab.name == "third") {
          this.$emit("getranking", -1, null);
        }
      }
    },
    getRankList(areaType, desc) {
      switch (areaType) {
        case 3:
          this.activeName = "first";
          break;
        case 4:
          this.activeName = "second";
          break;
        case -1:
          this.activeName = "third";
          break;
        default:
          break;
      }
      this.$emit("getranking", areaType, desc);
    },
  },
};
// 						background:url(../../assets/img/ranking1.png);
</script>
<style lang="stylus" rel="stylesheet/stylus">
.rankingList-listContent
  position: relative
  .el-tabs__item.is-disabled
    color: #303133;
  .el-tabs__nav
    .el-tabs__item
      width 100px
    .el-tabs__item:nth-child(4)
      width 110px
    text-align center
  .el-tabs__content
    overflow visible !important
  .berthNumer
    position: absolute;
    top: 22px;
    right: 170px;
  .utilization
    position: absolute;
    top: 22px;
    right: 20px;
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.rankingList-listContent
  .grid-content
    height 433px
    padding 10px 0
    .lj
      float right
      margin-top -30px
    .row:nth-last-child(1)
      border-bottom none
      margin 0 0
    .row
      width 100%
      margin 10px 0
      border-bottom 1px solid #EFE5F9
      height 40px
      line-height 18px
      color #333333
      .col
        width 100%
        .indexNumber
          display inline-block
          width 29px
          height 34px
          line-height 35px
          text-align center
          margin-right 10px
          font-size 15px
        .item
          font-size 15px
          position absolute
          top 8px
        .itemMoney
          float right
          font-size 15px
          height 34px
          line-height 35px
</style>
<style scoped>
.el-tabs__active-bar {
  background: 0;
}
.parkLable_top.isActive {
  border-bottom-color: #0f6eff !important;
}
.parkLable_bottom.isActive {
  border-top-color: #0f6eff !important;
}
</style>
