<template>
  <div class="recordChart">
    <div id="circularChart" class="circularChart"></div>
    <graphTitle class="title" :title="text"></graphTitle>
  </div>
</template>
<script>
import moment from "moment";
moment.suppressDeprecationWarnings = true;
// import hub from "./../../common/js/hub"
import graphTitle from "@/components/graphTitle";

export default {
  props: {
    chartDateType: {
      type: Number,
      default: 1,
    },
    tableData: {
      // type: Array,
      default: function () {
        return {};
      },
    },
    arrearageHistorytableData: {
      default: function () {
        return {};
      },
    },
    temporaryParktableData: {
      default: function () {
        return {};
      },
    },
    nightParktableData: {
      default: function () {
        return {};
      },
    },
    paySummaryReporttableData: {
      default: function () {
        return {};
      },
    },
    type: {
      default: "c1",
    },
  },
  data() {
    return {
      totalNum: 0,
      nameList: [],
      dataList: [],
      // 日期（共用）
      dateList: [],
      // 夜间停车 （以下）
      nightParknameList: [],
      nightParkdataList: [],
      // 营收总报表（以下）
      paySummaryReportdataList: [],
      seriesValue1: [
        {
          name: "应收总额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
        {
          name: "欠费额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "red",
          },
          itemStyle: {
            normal: {
              color: "red",
            },
          },
        },
      ],
      seriesValue2: [
        {
          name: "当日临停次数",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      seriesValue3: [
        {
          name: "选择期间",
          type: "line",
          data: [],
          lineStyle: {
            color: "#FF3300",
          },
          itemStyle: {
            normal: {
              color: "#FF3300",
            },
          },
        },
        // {
        //   name: "上周同期",
        //   type: "line",
        //   data: [],
        //   lineStyle: {
        //     color: "#0f6eff",
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: "#0f6eff",
        //     },
        //   },
        // },
        {
          name: "上月同期",
          type: "line",
          data: [],
          lineStyle: {
            color: "#9933CC",
          },
          itemStyle: {
            normal: {
              color: "#9933CC",
            },
          },
        },
      ],
      seriesValue4: [
        {
          name: "历史营收趋势",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      textTitle1: "欠费金额趋势",
      textTitle2: "过去30日临时停车趋势",
      // textTitle3: '日间/夜间停车时长趋势',
      textTitle3: "停车趋势分析",
      textTitle4: "历史营收趋势",
      text: "",
    };
  },
  watch: {
    chartDateType: {
      handler(val) {},
    },
    tableData: {
      handler(value) {
        this.totalNum = 0;
        this.dataList = [];
        this.nameList = [];
        for (var i = 0; i < value.length; i++) {
          this.totalNum += value[i].value * 1;
          this.nameList.push(value[i].dataDate);
          this.dataList.push({
            name: value[i].name,
            value: Number(value[i].value / 100).toFixed(2),
          });
        }
        this.totalNum = (this.totalNum / 100).toFixed(2);
        this.drawGraph();
      },
      deep: true,
    },
    // 欠费历史分析（欠费金额趋势）
    arrearageHistorytableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue1[0].data = [];
        this.seriesValue1[1].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 应收金额
          this.seriesValue1[0].data.push(Number(value[i].shouldPay / 100).toFixed(2));
          // 欠费额
          this.seriesValue1[1].data.push(Number(value[i].arrearageAmount / 100).toFixed(2));
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 临时停车统计（过去30日临时停车趋势）
    temporaryParktableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue2[0].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 过去30日临时停车趋势数据
          this.seriesValue2[0].data.push(value[i].shortParkNum);
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 夜间停车记录趋势
    nightParktableData: {
      handler(value) {
        this.totalNum = 0;
        this.nightParknameList = [];
        this.dateList = [];
        this.seriesValue3[0].data = [];
        this.seriesValue3[1].data = [];
        // this.seriesValue3[2].data = [];
        // for (var i = value.length - 1; i > 0; i--) {
        // for (var i = value.length - 1; i >= 0; i--) {
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 停车记录总数
          this.seriesValue3[0].data.push(value[i].entryCount);
          // 上周
          // this.seriesValue3[1].data.push(value[i].parkingCntWeek);
          // 上月
          this.seriesValue3[1].data.push(value[i].lastMonthEntryCount);
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 营收总报表的营收趋势
    paySummaryReporttableData: {
      handler(value) {
        this.totalNum = 0;
        let dateArr = [];
        this.dateList = [];
        this.seriesValue4[0].data = [];
        for (var i = 0; i < value.length; i++) {
          // this.totalNum += value[i].value * 1
          dateArr.push(value[i].date);
          this.seriesValue4[0].data.push(Number(value[i].amount / 100).toFixed(2));
        }
        this.dateList = this.uniq(dateArr);
        this.drawGraph();
      },
      deep: true,
    },
  },
  components: {
    graphTitle,
  },
  methods: {
    weDateFormat(date, strDate) {
      let weekOfDay = moment(date).format("E"); // 指定日期的周的第几天

      let lastMonday = moment(date)
        .subtract(weekOfDay - 1, "days")
        .format("YYYY-MM-DD"); // 周一日期
      let lastTuesday = moment(date)
        .subtract(weekOfDay - 2, "days")
        .format("YYYY-MM-DD"); // 周二日期
      let lastWednesday = moment(date)
        .subtract(weekOfDay - 3, "days")
        .format("YYYY-MM-DD"); // 周三日期
      let lastThursday = moment(date)
        .subtract(weekOfDay - 4, "days")
        .format("YYYY-MM-DD"); // 周四日期
      let lastFriday = moment(date)
        .subtract(weekOfDay - 5, "days")
        .format("YYYY-MM-DD"); // 周五日期
      let lastSaturday = moment(date)
        .subtract(weekOfDay - 6, "days")
        .format("YYYY-MM-DD"); // 周六日期
      let lastSunday = moment(date)
        .add(7 - weekOfDay, "days")
        .format("YYYY-MM-DD"); // 周日日期
      return {
        lastMonday,
        lastTuesday,
        lastWednesday,
        lastThursday,
        lastFriday,
        lastSaturday,
        lastSunday,
      };
    },
    // 去重
    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    drawGraph() {
      let that = this;
      let circularChart = this.$echarts.init(document.getElementById("circularChart"));
      let option = {
        // title: {
        //   text: that.type == "c1" ? that.textTitle1 : that.type == "c2" ? that.textTitle2 : that.type == "c3" ? that.textTitle3 : that.textTitle4
        // },
        legend: {
          data:
            that.type == "c1"
              ? ["应收总额", "欠费额"]
              : that.type == "c2"
              ? ["当日临停次数"]
              : that.type == "c3"
              ? ["选择期间", "上月同期"]
              : ["历史营收趋势"],
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            const res = that.nightParktableData.find((item) => {
              console.log();
              return item.dataDate == params[0].axisValue;
            });
            const index = that.nightParktableData.findIndex((item) => {
              return item.dataDate == params[0].axisValue;
            });
            let tooltip = "";
            let marker0 = params[0] && params[0].marker ? params[0].marker : "";
            let marker1 = params[1] && params[1].marker ? params[1].marker : "";
            // let marker2 = params[2] && params[2].marker ? params[2].marker : "";
            // console.log(that.weDateFormat(res.dataDate).lastSaturday, "5555555555");
            let val = `${moment(that.weDateFormat(res.dataDate).lastSunday).format(
              "YYYY-MM-DD"
            )} 23:59:59`;
            let nowT = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            let date = "";
            let isBefore = moment(nowT).isBefore(val);
            if (isBefore) {
              date = `${res.dataDate} 00:00:00 - ${nowT}`;
            } else {
              date = `${res.dataDate} 00:00:00 - ${val}`;
            }
            tooltip += `${date}</br>`;
            if (marker0) {
              tooltip += marker0 + `选择期间停车记录数: ${res.entryCount} 次` + "</br>";
            }
            if (marker1) {
              tooltip += marker1 + `上月同期停车记录数: ${res.lastMonthEntryCount} 次` + "</br>";
            }
            // if (marker2) {
            //   tooltip += marker2 + `上月同期停车记录数: ${res.parkingCntMonth} 次` + "</br>";
            // }
            // tooltip += `停车记录总数: ${res.allPark}(次)` + "</br>";
            // console.log(res);
            // console.log(params);
            return tooltip;
          },
        },
        toolbox: {
          show: true,
          feature: {
            //		            magicType: {show: true, type: ['stack', 'tiled']},
            //		            saveAsImage: {show: true}
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateList,
        },
        yAxis: {
          type: "value",
          name: "停车记录数(次)",
        },
        series:
          that.type == "c1"
            ? that.seriesValue1
            : that.type == "c2"
            ? that.seriesValue2
            : that.type == "c3"
            ? that.seriesValue3
            : that.seriesValue4,
      };
      circularChart.setOption(option, true);
    },
  },
  mounted() {
    this.drawGraph();
  },
  created() {
    this.text =
      this.type == "c1"
        ? this.textTitle1
        : this.type == "c2"
        ? this.textTitle2
        : this.type == "c3"
        ? this.textTitle3
        : this.textTitle4;
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.recordChart
  position relative
  .circularChart
    width 100%
    height 300px
    overflow hidden
  .title
    position absolute
    left 20px
    top 10px
</style>
